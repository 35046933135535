import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1024 1024">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">

  

<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M312.719604,464.999878 
	C312.714203,448.669647 312.827209,432.837830 312.627289,417.010010 
	C312.571930,412.625610 313.662964,410.727783 318.472687,410.793640 
	C335.966156,411.033203 353.466095,410.760010 370.961029,410.935669 
	C403.280396,411.260101 425.582031,442.045319 415.550293,472.723480 
	C413.987396,477.503113 415.100037,479.844025 418.886444,482.602020 
	C441.530212,499.095428 453.613037,521.602417 453.912628,549.498108 
	C454.390045,593.955872 420.115173,628.665222 379.289673,632.241211 
	C358.687286,634.045715 337.994110,632.624634 317.344696,632.923157 
	C313.967651,632.971985 312.555847,631.463013 312.681793,628.059875 
	C312.841888,623.733521 313.001556,619.371155 312.629211,615.070496 
	C312.194214,610.046448 314.460815,608.791992 319.027924,608.838989 
	C335.856354,609.012268 352.726593,608.201233 369.508759,609.104431 
	C398.333344,610.655640 422.012634,589.067993 424.989960,564.032654 
	C427.360718,544.097534 427.974701,523.887146 413.782074,506.938812 
	C403.620667,494.804382 390.562805,487.913544 374.792816,487.457733 
	C356.478424,486.928345 338.136932,487.342743 319.807220,487.331909 
	C312.768585,487.327759 312.757721,487.308228 312.729767,480.496063 
	C312.709229,475.497375 312.721924,470.498566 312.719604,464.999878 
M376.103821,466.071991 
	C380.225983,463.334808 381.737579,459.544769 380.214996,454.840332 
	C378.647766,449.997955 374.719788,448.452576 370.047485,448.332947 
	C366.718109,448.247711 363.383240,448.231812 360.053925,448.312988 
	C343.814667,448.708954 346.570923,445.104004 346.312164,462.874390 
	C346.309723,463.040771 346.332275,463.207916 346.324036,463.373749 
	C346.197235,465.928833 347.552429,467.055450 349.964386,467.008545 
	C358.439423,466.843750 366.937408,467.751160 376.103821,466.071991 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M500.001099,553.711060 
	C574.821716,553.722839 649.142700,553.734497 723.463684,553.747742 
	C730.673584,553.749023 730.528076,553.752625 730.488403,560.895630 
	C730.418091,573.554565 730.260925,586.225037 730.728699,598.868896 
	C730.912842,603.846069 729.434631,605.296143 724.652039,605.263916 
	C700.990356,605.104492 677.327087,605.182312 653.664368,605.184265 
	C599.006775,605.188782 544.349243,605.204834 489.691650,605.197144 
	C481.730835,605.195984 481.720459,605.147644 481.706665,597.196777 
	C481.684692,584.532227 481.798370,571.865723 481.595337,559.204224 
	C481.530182,555.140808 482.794403,553.395325 487.007233,553.659546 
	C491.156738,553.919739 495.335510,553.713196 500.001099,553.711060 
M529.795654,576.801941 
	C528.555847,573.563721 527.372498,574.095886 525.991150,576.764893 
	C525.239197,578.217896 526.065918,580.648682 523.513062,581.079956 
	C519.097778,572.784851 519.097778,572.784851 513.140747,580.997681 
	C510.492279,579.791077 512.206238,575.641113 509.018524,574.774536 
	C506.452271,575.175842 507.494263,578.869873 503.964722,579.446899 
	C503.559479,578.639893 502.891144,577.308838 502.222778,575.977783 
	C501.939697,579.978088 501.104767,583.876038 505.163239,585.597168 
	C507.782867,584.113770 506.594025,580.804199 508.877380,579.706299 
	C510.143799,579.875916 510.069916,580.833801 510.340851,581.578491 
	C510.931244,583.201233 510.795624,585.683655 513.344666,585.438782 
	C515.344238,585.246704 515.325684,583.112732 515.884399,581.565613 
	C516.523438,579.795837 516.193115,577.483032 518.363037,576.451050 
	C519.547241,577.145264 519.696655,578.367432 520.194397,579.399597 
	C521.215637,581.517578 520.504822,585.197937 523.650085,585.381897 
	C526.816650,585.567139 525.520386,581.632324 527.018738,580.021790 
	C527.207153,579.819275 527.569092,579.778198 527.930603,579.629456 
	C530.203735,580.958374 528.774292,585.216003 532.632751,585.487488 
	C534.133423,584.629761 534.350159,582.990784 534.787354,581.381042 
	C535.243286,579.701965 535.267273,577.725464 537.199280,576.581421 
	C538.426636,577.167053 538.408691,578.475220 538.910889,579.432739 
	C540.003113,581.515259 539.323792,585.179382 542.476685,585.353271 
	C545.571716,585.524048 544.474670,581.617737 546.030579,580.036133 
	C546.223572,579.839966 546.594299,579.818665 546.864685,579.722656 
	C547.859253,580.030640 547.972046,580.899475 548.167847,581.693604 
	C548.562256,583.292542 548.652466,585.401855 550.767273,585.428040 
	C553.281067,585.459167 553.207031,582.969910 553.513000,581.330139 
	C553.822266,579.673035 555.177734,578.042969 554.104004,576.114502 
	C552.344238,576.976562 553.267517,579.935974 550.814819,580.062378 
	C548.513733,579.164124 549.498718,575.966858 547.328918,574.991272 
	C544.042175,575.104187 545.676331,579.786865 542.577393,580.002991 
	C537.273804,572.100830 536.720764,572.149536 532.242615,580.962036 
	C530.329895,580.539368 530.596497,578.701599 529.795654,576.801941 
M712.733459,579.866638 
	C712.544800,578.277588 713.219177,576.509705 711.540649,574.925659 
	C709.430054,575.495789 707.161194,574.272278 704.928711,574.611694 
	C702.036072,575.051453 700.781372,577.521057 700.719666,580.119873 
	C700.572449,586.318237 705.341064,585.794617 709.681946,585.734619 
	C709.035767,592.182861 704.549438,588.309265 701.216309,588.887207 
	C703.647217,591.991455 706.278809,592.319153 709.104919,591.568909 
	C711.724060,590.873596 712.706238,588.823853 712.711426,586.232788 
	C712.715027,584.401062 712.722534,582.569275 712.733459,579.866638 
M654.686279,582.099915 
	C654.412354,579.710510 655.456665,577.116516 653.691650,574.939819 
	C652.119019,574.862610 650.631287,574.932373 649.195862,574.691101 
	C645.550354,574.078369 643.254089,575.676880 642.579346,579.095459 
	C642.017822,581.940491 643.136658,585.098572 646.037598,585.401306 
	C648.870972,585.696838 653.133667,588.091187 654.686279,582.099915 
M589.640808,581.220886 
	C589.436523,579.147461 590.253540,576.903442 588.512817,574.847778 
	C587.002808,574.847778 585.341736,575.099731 583.788025,574.798035 
	C579.945068,574.051880 578.062073,576.026733 577.447021,579.350403 
	C576.844055,582.608643 578.679626,585.702271 581.647644,585.382690 
	C584.211182,585.106567 589.344421,588.394348 589.640808,581.220886 
M677.112183,585.061279 
	C678.342407,581.702637 678.053894,578.362244 677.202087,574.966125 
	C675.240295,574.823364 673.479675,574.719360 671.723816,574.562439 
	C667.884460,574.219360 666.182495,576.373413 666.087891,579.812073 
	C665.989746,583.377258 667.429932,585.948608 671.575684,585.791748 
	C673.212830,585.729797 674.846313,585.571594 677.112183,585.061279 
M615.415283,574.936890 
	C613.942932,574.872009 612.468994,574.832764 610.998596,574.737183 
	C607.285767,574.496094 605.297974,576.320679 605.279053,579.935547 
	C605.259888,583.594299 606.876465,586.220825 610.987061,585.593872 
	C612.733276,585.327454 614.639709,586.115173 616.493469,584.777710 
	C616.907837,581.673523 617.578491,578.452454 615.415283,574.936890 
M569.770813,585.665894 
	C574.302307,585.986511 575.848328,583.099365 575.476135,579.415161 
	C574.939697,574.105042 570.910828,574.289734 567.378906,575.164246 
	C565.529785,574.101501 566.672485,571.433167 563.876892,571.211365 
	C563.004883,575.913574 563.219910,580.533936 563.653198,585.518921 
	C565.810791,585.518921 567.423340,585.518921 569.770813,585.665894 
M640.187439,579.913269 
	C640.424744,576.752136 641.069580,573.533264 639.207214,570.565796 
	C636.545044,571.300659 638.166931,574.182861 635.909790,575.295410 
	C633.541931,573.841980 631.108948,574.273926 629.313416,576.867920 
	C627.823120,579.020813 628.029236,581.255554 629.388794,583.407104 
	C632.068115,587.647278 635.912720,584.796265 639.043762,585.291321 
	C640.299316,583.723328 639.970459,582.164856 640.187439,579.913269 
M592.719971,571.688232 
	C592.389099,576.185913 591.929077,580.692505 592.993286,585.107422 
	C595.391602,585.126404 594.871155,582.227844 596.813965,582.645508 
	C598.697327,583.040894 598.891907,586.309387 602.069580,585.255005 
	C599.386475,582.309387 598.674683,579.124390 601.124146,575.773193 
	C598.637451,575.147339 598.213074,578.531616 596.638123,577.813354 
	C593.596619,576.098389 596.965698,572.016541 592.719971,571.688232 
M689.044495,584.953918 
	C690.001160,583.811218 689.600708,582.422180 689.719604,581.133484 
	C689.888184,579.307556 689.794922,577.105164 692.275146,577.020447 
	C694.986633,576.927917 695.140930,579.148682 695.351685,581.223877 
	C695.504150,582.725830 694.724304,584.658569 697.224121,585.615784 
	C699.213013,582.541321 698.923340,579.273682 697.315491,576.442810 
	C694.745789,571.918396 690.702637,576.917114 686.753784,574.367859 
	C687.833069,578.654480 685.541443,582.140991 689.044495,584.953918 
M661.362366,579.050598 
	C661.962585,577.759644 662.562805,576.468628 663.168274,575.166321 
	C661.632812,574.082214 661.294922,572.169434 659.357666,571.539307 
	C655.034485,575.181030 658.911072,579.415039 658.753967,583.281921 
	C658.684082,585.001404 660.621338,586.268921 662.934265,585.236267 
	C662.801392,583.231567 660.765198,581.837097 661.362366,579.050598 
M620.262024,578.997864 
	C620.579407,581.052734 619.459473,583.444763 622.062622,585.857544 
	C622.495422,581.521362 623.429626,578.051147 627.218079,575.703430 
	C621.053284,575.128784 621.053284,575.128784 620.262024,578.997864 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M406.902283,554.390991 
	C400.934143,578.369690 384.558472,591.156189 360.280640,591.167297 
	C346.458893,591.173584 332.635712,591.065308 318.816254,591.245239 
	C314.473633,591.301758 312.564728,590.170410 312.612213,585.358215 
	C312.737366,572.672729 312.528839,572.671814 325.274017,572.672119 
	C337.763550,572.672424 350.253235,572.704712 362.742615,572.660034 
	C377.463776,572.607361 388.703949,561.636841 388.716309,547.386475 
	C388.728912,532.857361 377.950806,522.342651 362.833649,522.269348 
	C348.013062,522.197449 333.190094,522.108765 318.372101,522.313049 
	C313.943207,522.374146 312.649994,520.769348 312.644257,516.488831 
	C312.626984,503.632812 312.477203,503.642334 325.053314,503.665131 
	C337.043213,503.686829 349.033264,503.717651 361.023132,503.689209 
	C390.394318,503.619507 409.661438,524.679871 406.902283,554.390991 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M357.080841,501.385498 
	C344.111481,501.566345 331.627533,501.770935 319.142822,501.908112 
	C315.233368,501.951050 312.593079,500.982483 312.588135,495.999207 
	C312.583435,491.225586 314.065002,489.596924 318.891998,489.671814 
	C335.044617,489.922516 351.203430,489.731995 367.359253,489.823700 
	C396.210388,489.987457 417.692505,507.007904 422.455841,535.498352 
	C427.338470,564.702271 418.190369,588.770142 391.279480,601.958313 
	C383.640533,605.701965 375.329529,606.791199 367.008789,606.941772 
	C350.857605,607.234009 334.695801,606.908264 318.542267,607.125549 
	C313.826813,607.189026 312.502441,605.367371 312.636597,600.917603 
	C312.858978,593.542542 312.823334,593.091125 318.351471,593.065613 
	C334.507294,592.990967 350.663849,593.086853 366.820129,593.103271 
	C390.580200,593.127380 409.168335,574.657837 409.757233,550.887878 
	C410.036438,539.618286 408.576538,529.012878 401.873444,519.563660 
	C393.588531,507.884644 382.290375,501.900330 368.058655,501.373291 
	C364.565979,501.243958 361.063782,501.372955 357.080841,501.385498 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M489.123718,435.067902 
	C494.764191,435.081543 499.934662,434.859833 505.072510,435.173004 
	C511.518127,435.565887 517.820312,436.744202 520.944153,443.499634 
	C523.963440,450.029022 521.482483,455.418579 517.042664,459.997833 
	C517.056519,461.442902 517.963196,461.982086 518.667480,462.657837 
	C523.042419,466.855713 524.442566,471.935486 522.641785,477.671692 
	C520.710144,483.824860 515.796021,486.797485 509.838165,487.176392 
	C501.720795,487.692657 493.547119,487.294891 485.398163,487.359192 
	C482.588593,487.381348 481.641418,486.016968 481.650604,483.356079 
	C481.701080,468.719391 481.754364,454.081726 481.627808,439.445923 
	C481.578979,433.798828 485.663544,435.489227 489.123718,435.067902 
M508.064117,468.183044 
	C505.658783,465.754791 502.566864,466.436890 499.640564,466.282715 
	C496.491150,466.116760 495.228455,467.482147 495.196808,470.668884 
	C495.139832,476.412567 498.284729,478.343506 504.965729,476.214325 
	C508.667114,475.034698 510.631897,472.805237 508.064117,468.183044 
M499.719727,455.801575 
	C500.884308,455.774261 502.050323,455.775055 503.213135,455.713898 
	C505.887482,455.573212 507.659943,454.352753 507.859650,451.516479 
	C508.080475,448.379974 506.127441,447.025726 503.405823,446.511444 
	C502.273804,446.297516 501.085632,446.334839 499.923187,446.337952 
	C497.678497,446.343933 495.354828,446.395020 495.317505,449.510040 
	C495.284821,452.237305 494.446320,455.533630 499.719727,455.801575 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M522.236816,537.184937 
	C516.546326,542.950317 509.924561,545.497620 502.215698,545.408569 
	C496.723755,545.345093 491.230713,545.372559 485.738159,545.370667 
	C483.576599,545.369934 481.689423,545.098145 481.691986,542.248962 
	C481.706207,526.298401 481.698822,510.347839 481.698822,493.861603 
	C492.494019,492.883728 502.997925,491.985657 513.001831,495.593292 
	C529.232788,501.446503 533.603638,521.392700 522.236816,537.184937 
M508.178314,507.148895 
	C505.361267,505.442200 502.266754,505.245300 499.063904,505.262085 
	C496.317474,505.276459 495.172119,506.414124 495.202271,509.146240 
	C495.279053,516.112366 495.283844,523.080017 495.225800,530.046326 
	C495.202057,532.897339 496.625610,533.656738 499.251312,533.741089 
	C506.254333,533.965698 511.509674,531.006165 513.621277,525.351318 
	C516.042542,518.867249 514.173645,511.871399 508.178314,507.148895 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M571.228638,481.855103 
	C571.705078,486.312378 569.814514,487.830811 566.009521,487.396729 
	C562.842346,487.035431 559.325317,488.453491 556.655029,485.414886 
	C556.361511,485.080872 555.094666,485.420288 554.362305,485.680756 
	C538.589600,491.290131 526.654053,482.941895 526.838562,466.403015 
	C527.019226,450.202820 539.137512,441.695190 554.024292,447.330292 
	C555.088806,447.733246 556.226196,448.671021 557.221191,447.589508 
	C560.392334,444.142517 564.407776,446.015259 568.029175,445.868439 
	C571.434814,445.730377 571.187378,448.648254 571.197449,450.950928 
	C571.241882,461.090912 571.225403,471.231110 571.228638,481.855103 
M557.518066,467.257935 
	C557.288208,465.970093 557.215881,464.628662 556.799744,463.404114 
	C555.343689,459.119781 551.636169,456.602478 547.744324,457.455872 
	C543.241394,458.443329 541.184570,461.539795 541.101868,466.064789 
	C541.007446,471.229095 543.595764,474.997986 547.773865,475.738007 
	C552.551880,476.584290 555.303894,474.359863 557.518066,467.257935 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M649.503662,447.889954 
	C652.016418,448.311432 652.748901,445.845062 654.996521,445.635529 
	C664.428528,444.756256 665.284607,445.348541 665.283325,454.828949 
	C665.282104,463.989532 665.129639,473.153442 665.338440,482.309174 
	C665.429504,486.302612 663.860840,487.877747 660.058655,487.364929 
	C656.884155,486.936798 652.894165,488.970001 651.077881,484.447845 
	C639.824341,489.763428 631.652039,488.795929 625.923706,481.513123 
	C619.847168,473.787598 619.732422,459.541321 625.685913,451.986145 
	C631.093445,445.123932 639.650208,443.592743 649.503662,447.889954 
M651.275330,469.633362 
	C651.833069,467.667053 651.831299,465.699402 651.248718,463.742126 
	C649.447144,457.690338 642.767334,455.266174 638.304504,459.121338 
	C634.841675,462.112579 634.608704,466.067780 635.764465,470.097260 
	C636.701843,473.365265 638.918091,475.540833 642.526855,475.828217 
	C646.727905,476.162750 649.518066,474.267883 651.275330,469.633362 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M575.280640,507.232300 
	C575.313171,517.873840 575.327209,528.037048 575.323364,538.200256 
	C575.320618,545.466370 575.309814,545.478516 567.803528,545.393677 
	C565.188416,545.364075 562.364197,545.883911 560.988953,542.852234 
	C550.551453,548.443542 542.085815,546.915222 535.983704,539.408081 
	C529.853271,531.866028 529.861938,517.830933 535.691833,510.308136 
	C541.454346,502.872314 549.325378,501.568787 560.584412,506.285156 
	C564.014465,502.190613 568.674927,503.859802 573.004944,503.943176 
	C574.610168,503.974060 575.150269,505.294678 575.280640,507.232300 
M553.523376,533.939453 
	C558.963135,533.009094 561.319885,530.646118 561.682373,525.669189 
	C562.017456,521.068054 560.481506,517.444092 555.895447,515.802917 
	C552.053711,514.428162 549.086365,516.034058 546.869995,519.167358 
	C544.429565,522.617371 544.823120,527.884155 547.603088,531.211243 
	C548.954468,532.828552 550.646545,533.723633 553.523376,533.939453 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M619.401367,543.914978 
	C608.283203,536.247864 606.261963,518.325867 615.128540,508.807465 
	C620.762329,502.759521 628.620789,501.825592 638.227417,506.145233 
	C640.010803,506.344849 640.494019,504.170105 642.233826,503.771057 
	C650.623657,501.846832 653.355591,503.881012 653.356323,512.465942 
	C653.357178,521.965820 653.266357,531.466614 653.376160,540.965149 
	C653.412415,544.098206 652.393616,545.792236 649.087158,545.369629 
	C645.777710,544.946716 642.102234,546.662781 639.228271,543.397461 
	C638.459412,542.523926 637.047485,543.656128 636.034668,544.182556 
	C630.582092,547.016785 625.156494,546.806580 619.401367,543.914978 
M639.435242,521.406616 
	C638.871521,520.399719 638.470276,519.245544 637.714905,518.412415 
	C634.982239,515.398682 631.690125,514.336853 627.916626,516.349182 
	C624.134521,518.366089 622.437378,523.113953 623.835876,527.538696 
	C625.114746,531.585327 627.713440,534.125671 632.180908,533.903076 
	C636.690430,533.678223 638.985718,530.878723 639.622620,526.637756 
	C639.840576,525.186462 639.622742,523.669800 639.435242,521.406616 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M592.548950,474.857361 
	C592.405701,476.987823 592.349426,478.818451 592.332092,480.649445 
	C592.268433,487.373047 592.273254,487.373169 585.801819,487.389771 
	C578.745056,487.407867 578.726318,487.407837 578.717468,480.161682 
	C578.700378,466.172791 578.942322,452.177917 578.588562,438.197876 
	C578.444763,432.513245 581.522522,432.617676 585.502930,432.622742 
	C589.436584,432.627716 592.875366,432.395508 592.393127,438.131866 
	C591.990051,442.926331 592.295410,447.784180 592.368225,452.612762 
	C592.394897,454.385925 592.092102,456.300079 594.390747,458.455750 
	C599.701721,448.685272 606.858765,442.172302 619.294678,446.737793 
	C616.389954,451.657471 612.415771,455.630951 608.845886,459.891296 
	C603.263672,466.553253 603.231995,466.498749 608.689636,473.030396 
	C612.104431,477.117279 615.465576,481.249207 618.825012,485.381866 
	C618.998230,485.594971 618.928162,486.005768 618.966553,486.325195 
	C610.650085,489.467590 604.159607,487.546234 598.888123,480.814514 
	C597.095215,478.524872 596.306641,475.050995 592.548950,474.857361 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M332.031555,557.828491 
	C325.614868,566.199280 316.259735,568.734009 307.406189,564.752075 
	C299.837677,561.348083 294.607086,551.933350 296.073669,544.354248 
	C298.083435,533.968262 305.451843,527.087830 315.614349,526.947205 
	C322.504059,526.851807 328.234680,530.038147 332.019043,535.865967 
	C336.603302,542.925598 336.545135,550.255066 332.031555,557.828491 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M606.799194,543.691528 
	C596.614868,548.732910 587.132080,543.869507 585.410217,533.354309 
	C584.785583,529.540100 585.043884,525.728882 584.891907,521.916260 
	C584.778198,519.064819 585.551270,516.124878 581.437256,514.710510 
	C578.918884,513.844666 579.219543,505.034332 581.778809,504.130920 
	C584.779724,503.071594 585.063904,501.188873 584.880615,498.781250 
	C584.574036,494.754791 586.572632,493.668427 590.293396,493.692047 
	C597.492981,493.737823 597.584290,493.565460 598.961304,500.718719 
	C599.406738,503.032440 600.623474,503.816132 602.714233,503.731781 
	C607.383118,503.543427 606.901001,506.906647 607.030212,509.891113 
	C607.165161,513.011047 606.818420,515.688477 602.646362,515.352661 
	C599.758240,515.120178 599.076538,516.916382 598.739136,519.355225 
	C598.229919,523.035706 598.663818,526.654541 599.003479,530.290771 
	C599.132141,531.667969 599.583923,533.365540 601.023071,533.391724 
	C608.437622,533.526794 607.173584,538.664307 606.799194,543.691528 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M686.911865,465.213440 
	C685.861755,471.234528 686.338867,476.907349 686.421509,482.548248 
	C686.476318,486.287384 685.082520,487.732666 681.454346,487.398071 
	C679.802246,487.245728 678.126831,487.310791 676.462769,487.332916 
	C673.959412,487.366241 672.835449,486.125458 672.836243,483.664612 
	C672.839905,472.343628 672.827087,461.022644 672.800842,449.701691 
	C672.794678,447.049927 673.978088,445.526917 676.711975,445.777344 
	C680.572937,446.131012 685.241943,443.805298 687.445984,448.975098 
	C688.077454,448.899811 688.443848,448.944824 688.722412,448.809326 
	C691.660522,447.380280 694.740601,444.230225 697.836365,446.099579 
	C700.999817,448.009857 698.851135,452.203186 699.257385,455.363037 
	C699.646667,458.391235 698.006470,459.342560 695.123779,459.485626 
	C691.522522,459.664368 688.140442,460.639923 686.911865,465.213440 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M708.995972,582.130249 
	C707.455261,584.070618 705.916748,583.960938 704.584717,582.518921 
	C703.456604,581.297668 703.528320,579.730652 704.373779,578.349976 
	C705.022339,577.290771 706.131592,576.639648 707.351746,577.178467 
	C709.394104,578.080383 709.551880,579.877441 708.995972,582.130249 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M566.840576,579.169800 
	C567.900146,576.957275 569.240479,576.512390 570.896851,577.870239 
	C572.018860,578.790039 572.275696,580.087219 571.877380,581.444153 
	C571.450562,582.897766 570.385071,583.639282 568.892029,583.368958 
	C566.732056,582.978088 566.703857,581.255554 566.840576,579.169800 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M634.170410,577.329712 
	C637.437134,578.084045 637.534973,579.956421 636.180847,582.166382 
	C635.502686,583.273315 634.120605,583.745605 633.192017,582.838989 
	C631.236511,580.929565 631.870178,579.024658 634.170410,577.329712 
z"/>

</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
